@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;

  src: url("./fonts/Poppins-Regular-Converted.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;

  src: url("./fonts/Poppins-Bold-Converted.woff2") format("woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;

  src: url("./fonts/Poppins-SemiBold-Converted.woff2") format("woff2");
}
